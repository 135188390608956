import { ActionIcon, Group, Loader } from "@mantine/core";
import {
  IconEye as Eye,
  IconEyeOff as EyeOff,
  IconGlobe as Globe,
  IconInfoCircle as InfoCircle,
  IconPlayerPause as PlayerPause,
  IconPlayerPlay as PlayerPlay,
  IconStar as Star,
} from "@tabler/icons-react";
import React from "react";

import { setPhotosFavorite, setPhotosHidden, setPhotosPublic } from "../../actions/photosActions";
import { shareAddress } from "../../api_client/apiClient";
import { playerActions } from "../../store/player/playerSlice";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { copyToClipboard } from "../../util/util";

type Props = {
  photosDetail: any;
  isPublic: boolean;
  lightboxSidebarShow: boolean;
  closeSidepanel: () => void;
};

export function Toolbar(props: Props) {
  const dispatch = useAppDispatch();
  const { favorite_min_rating: favoriteMinRating } = useAppSelector(store => store.user.userSelfDetails);
  const { photosDetail, isPublic, lightboxSidebarShow, closeSidepanel } = props;
  const { playing: playerPlaying, loading: playerLoading } = useAppSelector(store => store.player);

  function playButton(photo) {
    if (!photo || photo.embedded_media.length === 0) {
      return null;
    }
    function togglePlay() {
      if (playerPlaying) {
        dispatch(playerActions.pause());
      } else {
        dispatch(playerActions.play());
      }
    }
    return (
      <ActionIcon onClick={() => togglePlay()}>
        {playerLoading && <Loader color="grey" />}
        {!playerLoading && playerPlaying ? <PlayerPause color="grey" /> : <PlayerPlay color="grey" />}
      </ActionIcon>
    );
  }

  return (
    <Group style={{ paddingBottom: 10, paddingRight: 5 }}>
      {!photosDetail && (
        <ActionIcon loading disabled={isPublic}>
          <Eye color="grey" />
        </ActionIcon>
      )}
      {!photosDetail && (
        <ActionIcon loading disabled={isPublic}>
          <Star color="grey" />
        </ActionIcon>
      )}
      {!photosDetail && (
        <ActionIcon loading disabled={isPublic}>
          <Globe color="grey" />
        </ActionIcon>
      )}
      {playButton(photosDetail)}
      {photosDetail && (
        <ActionIcon
          disabled={isPublic}
          onClick={() => {
            const { image_hash: imageHash } = photosDetail;
            const val = !photosDetail.hidden;
            dispatch(setPhotosHidden([imageHash], val));
          }}
        >
          {photosDetail.hidden ? <EyeOff color="red" /> : <Eye color="grey" />}
        </ActionIcon>
      )}
      {photosDetail && (
        <ActionIcon
          disabled={isPublic}
          onClick={() => {
            const { image_hash: imageHash } = photosDetail;
            const val = !(photosDetail.rating >= favoriteMinRating);
            dispatch(setPhotosFavorite([imageHash], val));
          }}
        >
          <Star color={photosDetail.rating >= favoriteMinRating ? "yellow" : "grey"} />
        </ActionIcon>
      )}
      {photosDetail && (
        <ActionIcon
          disabled={isPublic}
          onClick={() => {
            const { image_hash: imageHash } = photosDetail;
            const val = !photosDetail.public;
            dispatch(setPhotosPublic([imageHash], val));
            copyToClipboard(
              // edited from serverAddress.replace('//','') + "/media/thumbnails_big/" + image_hash + ".jpg"
              // as above removed the domain and just left /media/thumbnails_big/" + image_hash + ".jpg"  *DW 12/9/20
              // Not location of shared photo link Reverted to orgiinal *DW 12/13/20
              // Removed the .jpg as this is not a real link, now it is having webp as extension generated by the link *VARET 07/25/22
              `${shareAddress}/media/thumbnails_big/${imageHash}`
            );
          }}
        >
          <Globe color={photosDetail.public ? "green" : "grey"} />
        </ActionIcon>
      )}
      <ActionIcon onClick={() => closeSidepanel()}>
        <InfoCircle color={lightboxSidebarShow ? "white" : "grey"} />
      </ActionIcon>
    </Group>
  );
}
